import * as React from "react";
import WhyUsContainer from "../containers/why-us";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="Why Choose Us">
        <WhyUsContainer />
      </DefaultLayout>
    </>
  );
};
