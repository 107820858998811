import React, { ReactElement } from "react";
import HCSec5 from "../../hoc/hc-sec-5"


export interface WhyUsContainerProps { }


export default function WhyUsContainer(
  props: WhyUsContainerProps
): ReactElement | null {

  return (
    <>
      <div id="why-us" className="mb-16">
        <HCSec5 />
      </div>
    </>
  )
}
